import * as React from 'react';
import Lightbox from 'react-image-lightbox';

import Layout from '../containers/Layout';
import Seo from '../components/Seo';
import useTranslation from '../hooks/useTranslation';
import Content from '../containers/Content';

const IMAGE_BASE_PATH = '/images/gallery/';
const images = [
  '_JKP7958.jpg',
  '_JKP7934.jpg',
  '_JKP7941.jpg',
  '_JKP7912_BW.jpg',
  '_JKP7899_BW.jpg',
  '_JKP7928_BW.jpg',
  '_JKP7912_1.jpg',
  '_JKP7919.jpg',
  '_JKP7899.jpg',
  '_JKP7879.jpg',
  '_JKP7871.jpg',
  '_JKP7890.jpg',
];
const imageThumbs = images.map((image) => `${IMAGE_BASE_PATH}thumb/${image}`);
const imagePaths = images.map((image) => `${IMAGE_BASE_PATH}${image}`);

function Gallery({ pageContext: { currentPath } }) {
  const { t, locale } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  function handleOpen(e, index) {
    e.preventDefault();

    setPhotoIndex(index);
    setOpen(true);
  }

  return (
    <Layout background="hero" title={t('SEO.GALLERY.TITLE')}>
      <Seo
        title={t('SEO.GALLERY.TITLE')}
        location={currentPath}
        lang={locale}
      />

      <Content title={t('GALLERY.TITLE')}>
        <p>{t('GALLERY.DESCRIPTION')}</p>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {imagePaths.map((image, index) => (
            <a
              href={image}
              onClick={(e) => handleOpen(e, index)}
              key={index}
              className="col-span-1 group"
            >
              <figure className="aspect-h-9 aspect-w-16 relative overflow-hidden">
                <img
                  src={imageThumbs[index]}
                  alt="Gallery"
                  loading="lazy"
                  className="absolute inset-0 object-cover h-full w-full group-hover:scale-110 transition-transform"
                />
              </figure>
            </a>
          ))}
        </div>

        {open && (
          <Lightbox
            mainSrc={imagePaths[photoIndex]}
            nextSrc={imagePaths[(photoIndex + 1) % imagePaths.length]}
            prevSrc={
              imagePaths[
                (photoIndex + imagePaths.length - 1) % imagePaths.length
              ]
            }
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + imagePaths.length - 1) % imagePaths.length,
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imagePaths.length)
            }
            enableZoom={false}
          />
        )}
      </Content>
    </Layout>
  );
}

export default Gallery;
